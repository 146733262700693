import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    AUTH_LOGOUT,
    USER_REQUEST,
    USER_ALL,
    USER_ADD,
    USER_DELETE,
    USER_UPDATE,
    USER_ERROR,
    USER_SUCCESS,
    USER_ALL_SUCCESS,
    USER_LOGIN_SUCCESS,
    USER_TYPE
} from './define';

const state = {
    type: '',
    status: '',
    profile: {},
    users: { data: [], total: 0, pages: 0 }
};

const getters = {
    getUserType: state => state.type,
    getProfile: state => state.profile,
    isProfileLoaded: state => !!state.profile.name
};

const actions = {
    [USER_TYPE]: ({ state }) => {
        return new Promise((resolve, reject) => {
            apiCall({ url: 'auth/type' })
                .then(resp => {
                    if (resp.status && resp.type != undefined) {
                        state.type = resp.type;
                        resolve(resp.type);
                    } else {
                        reject(false);
                    }
                })
                .catch(err => {
                    reject(false);
                });
        });
    },
    [USER_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(USER_REQUEST);
            apiCall({ url: 'auth' })
                .then(resp => {
                    commit(USER_LOGIN_SUCCESS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    commit(USER_ERROR);
                    // if resp is unauthorized, logout, to
                    dispatch(AUTH_LOGOUT);
                });
        });
    },
    [USER_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(USER_REQUEST);
            apiCall({
                url: 'user/' + data.uuid,
                data: data.user || data.admin,
                method: 'PUT'
            })
                .then(resp => {
                    commit(USER_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(USER_ERROR);
                    reject(err);
                });
        });
    },
    [USER_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(USER_REQUEST);
            apiCall({
                url: 'user',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(USER_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(USER_ERROR);
                    reject(err);
                });
        });
    },
    [USER_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(USER_REQUEST);
            apiCall({
                url: 'user/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(USER_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(USER_ERROR);
                    reject(err);
                });
        });
    },
    [USER_ALL]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            apiCall({ url: 'user/getusers', data, method: 'POST' })
                .then(resp => {
                    if (resp.status) {
                        commit(USER_ALL_SUCCESS, resp);
                        resolve(true);
                    } else {
                        reject(new Error('Server Error'));
                    }
                })
                .catch(err => {
                    commit(USER_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [USER_REQUEST]: state => {
        state.status = 'loading';
    },
    [USER_SUCCESS]: state => {
        state.status = 'success';
    },
    [USER_LOGIN_SUCCESS]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'profile', resp.user);
    },
    [USER_ALL_SUCCESS]: (state, resp) => {
        state.status = 'success';
        Vue.set(state.users, 'data', resp.users);
        Vue.set(state.users, 'total', resp.total);
        Vue.set(state.users, 'pages', resp.pages);
    },
    [USER_ERROR]: state => {
        state.status = 'error';
    },
    [AUTH_LOGOUT]: state => {
        state.profile = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
