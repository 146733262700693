import Vue from 'vue';
import apiCall from '@/utils/apiCall';
import {
    PAYMENT_LIST,
    PAYMENT_ERROR,
    PAYMENT_REQUEST,
    PAYMENT_SUCCESS,
    PAYMENT_UPDATE
} from './define';

const state = {
    status: '',
    footer: {},
    payments: [],
    total: 0,
    pages: 0
};

const getters = {
    getPayments: state => state.payments,
    getPaymentTotal: state => state.total,
    getPaymentPages: state => state.pages,
    getPaymentFooter: state => state.footer
};

const actions = {
    [PAYMENT_REQUEST]: ({ commit }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(PAYMENT_REQUEST);
            apiCall({ url: 'payment/list', data, method: 'POST' })
                .then(resp => {
                    commit(PAYMENT_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(PAYMENT_ERROR);
                    reject(err);
                });
        });
    },
    [PAYMENT_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(PAYMENT_REQUEST);
            apiCall({
                url: 'payment/' + data.uuid,
                data: data.payment,
                method: 'PUT'
            })
                .then(resp => {
                    commit(PAYMENT_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(PAYMENT_ERROR);
                    reject(err);
                });
        });
    },
};

const mutations = {
    [PAYMENT_REQUEST]: state => {
        state.status = 'loading';
    },
    [PAYMENT_SUCCESS]: state => {
        state.status = 'success';
    },
    [PAYMENT_ERROR]: state => {
        state.status = 'error';
    },
    [PAYMENT_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'footer', resp.footer);
        Vue.set(state, 'payments', resp.payments);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
