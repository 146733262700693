import { getCookie } from '@/utils/cookie';
import moment from 'moment';
const lang = getCookie('user-lang');

const filters = {
    timeAgo: value => {
        return moment(value)
            .locale(lang)
            .fromNow();
    },
    getDate: (value, format = 'DD.MM.YYY HH:mm') => {
        return moment(value)
            .locale(lang)
            .format(format);
    },
    getIntDate: (value, format = 'DD.MM.YYYY') => {
        return moment(value, 'YYYYMMDD')
            .locale(lang)
            .format(format);
    },
    getIntTime: value => {
        return moment(value, 'HHmm').format('HH:mm');
    },
    getCurrency: value => {
        const currency = { try: '₺', usd: '$', eur: '€', gbp: '£' };

        return currency[value] !== undefined ? currency[value] : '';
    },
    getHtmlText: text => {
        return text&&text.trim()!=''?text.replace(/(?:\r\n|\r|\n)/g, '<br>'):'';
    }
};

export default filters;
