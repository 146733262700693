import Vue from 'vue'
import Vuex from 'vuex'
import {getCookie} from '@/utils/cookie'

import auth from './modules/auth'
import user from './modules/user'
import doctor from './modules/doctor'
import patient from './modules/patient'
import clinic from './modules/clinic'
import branch from './modules/branch'
import availability from './modules/availability'
import ctrycity from './modules/ctrycity'
import setting from './modules/setting'
import notify from './modules/notify'
import socket from './modules/socket'
import calendar from './modules/calendar'
import article from './modules/article'
import recipe from './modules/recipe'
import report from './modules/report'
import page from './modules/page'
import bank from './modules/bank'
import appointment from './modules/appointment'
import email from './modules/email'
import video from './modules/video'
import payment from './modules/payment'
import comment from './modules/comment'
import symptom from './modules/symptom'
import disease from './modules/disease'
import treatment from './modules/treatment'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userLang: getCookie('user-lang'),
		menuToggle: false
	},
	mutations: {
		changeMenuToggle: (state, status) => {
			state.menuToggle = status
		}
	},
	modules: {
		auth,
		user,
		doctor,
		patient,
		clinic,
		branch,
		availability,
		ctrycity,
		setting,
		notify,
		socket,
		calendar,
		article,
		recipe,
		report,
		page,
		bank,
		appointment,
		email,
		video,
		payment,
		comment,
		symptom,
		disease,
		treatment
	},
	strict: false
})
