import path from 'path'
import _ from 'lodash'

const env = process.env.NODE_ENV || 'production'

import configDevelopment from './development.js'
import configProduction from './production.js'

const configs = {
	development: configDevelopment,
	production: configProduction
}

var config = configs[env]

const defaults = {
	GOOGLE_CLIENT_ID: '1095235567303-qkisg3i8u29hrc9uckh2h1b2f1ta60fp.apps.googleusercontent.com',
	GOOGLE_CLIENT_SECRET: 'G3Kmz3FHcNhsykkcxbE72jO8',
	MONGODB_URI: 'mongodb://localhost:27017/edoktor',
	JWTKEY: 'h)v}EaP7[*Y$M-D+C*a5'
}

_.assign(config, defaults)

export default config
