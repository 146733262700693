<template>
    <div id="admin-panel" v-loading="false">
        <div :class="'wrapper'+($store.state.menuToggle?' toggleSideBar':'')">
            <notifications></notifications>
            <side-bar>
                <template slot="links">
                    <template  v-for="(item, index) in routes">
                    <sidebar-item :key="index" v-if="(item.meta.superadmin==undefined || (item.meta.superadmin&&$store.getters.getProfile.superadmin===true)) && item.meta.sidebar!==undefined && (item.meta.type===undefined || (item.meta.type && item.meta.type.indexOf($store.getters.getUserType)!==-1))" :link="{
                            name: $t('pages.'+item.name),
                            path: item.path,
                            icon: item.icon+' text-primary',
                        }">
                    </sidebar-item>
                    </template>
                    <a :href="frontendUrl" class="nav-link" v-if="$store.getters.getUserType=='patient'">
                        <i class="fas fa-calendar-alt text-primary"></i>
                        <span class="nav-link-text">{{$t('pages.cappointment')}}</span>
                    </a>

                </template>
            </side-bar>
            <div class="main-content pb-5">
                <navbar />

                <div @click="$sidebar.displaySidebar(false)">
                    <fade-transition :duration="200" origin="center top" mode="out-in">
                        <!-- your content here -->
                        <router-view></router-view>
                    </fade-transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { USER_REQUEST, BRANCH_LIST_REQUEST, COUNTRY_REQUEST, CITY_REQUEST, NOTIFY_REQUEST, SETTING_REQUEST } from '@/store/modules/define';
import { getCookie, setCookie } from '@/utils/cookie';
import Navbar from './Navbar.vue';
import { FadeTransition } from 'vue2-transitions';
import config from '@/config'
let self;

export default {
    components: {
        Navbar,
        FadeTransition
    },
    computed: {
        routes() {
            return this.$router.options.routes[0].children;
        }
    },
    data() {
        return {
            frontendUrl : config.VUE_APP_FRONTEND
        };
    },
    methods: {},
    mounted() {},
    created() {
        self = this;
        if (self.$store.getters.isAuthenticated) {
            self.$store.dispatch(USER_REQUEST).then(resp => {
                self.$store.dispatch(BRANCH_LIST_REQUEST);

                self.$store.dispatch(COUNTRY_REQUEST);
                self.$store.dispatch(CITY_REQUEST);
                self.$store.dispatch(NOTIFY_REQUEST);
                self.$store.dispatch(SETTING_REQUEST);

                self.$socket.io.opts.query = 'token=' + getCookie('user-token');
                self.$socket.open();

                self.sockets.subscribe('NOTIFY_EVENT', data => {
                    self.$store.dispatch(NOTIFY_REQUEST);
                });

                let userLang = getCookie('user-lang');
                let profile = self.$store.getters.getProfile;
                
                if (profile.lang && profile.lang.trim() != '' && profile.lang != userLang) {
                    setCookie('user-lang', profile.lang);
                    location.reload();
                }
            });
        }

        document.documentElement.setAttribute('lang', self.$i18n.locale);
    }
};
</script>