import Vue from 'vue';

import _ from 'lodash';
import apiCall from '@/utils/apiCall';
import {
    ARTICLE_ADD,
    ARTICLE_DELETE,
    ARTICLE_LIST,
    ARTICLE_ERROR,
    ARTICLE_REQUEST,
    ARTICLE_SUCCESS,
    ARTICLE_UPDATE,
    ARTICLE_IMG_LIST
} from './define';

const state = {
    status: '',
    articles: [],
    imageList: [],
    total: 0,
    pages: 0
};

const getters = {
    getArticles: state => state.articles,
    getArticleImages: state => state.imageList,
    getArticleTotal: state => state.total,
    getArticlePages: state => state.pages
};

const actions = {
    [ARTICLE_IMG_LIST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            apiCall({ url: '/article/media/list/get' })
                .then(resp => {
                    commit(ARTICLE_IMG_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [ARTICLE_REQUEST]: ({ commit, dispatch }, data = {}) => {
        return new Promise((resolve, reject) => {
            commit(ARTICLE_REQUEST);
            apiCall({ url: 'article/list', data, method: 'POST' })
                .then(resp => {
                    commit(ARTICLE_LIST, resp);
                    resolve(true);
                })
                .catch(err => {
                    commit(ARTICLE_ERROR);
                    reject(err);
                });
        });
    },
    [ARTICLE_UPDATE]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(ARTICLE_REQUEST);
            apiCall({
                url: 'article/' + data.uuid,
                data: data.article,
                method: 'PUT'
            })
                .then(resp => {
                    commit(ARTICLE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(ARTICLE_ERROR);
                    reject(err);
                });
        });
    },
    [ARTICLE_ADD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(ARTICLE_REQUEST);
            apiCall({
                url: 'article',
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    commit(ARTICLE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(ARTICLE_ERROR);
                    reject(err);
                });
        });
    },
    [ARTICLE_DELETE]: ({ commit }, uuid) => {
        return new Promise((resolve, reject) => {
            commit(ARTICLE_REQUEST);
            apiCall({
                url: 'article/' + uuid,
                method: 'DELETE'
            })
                .then(resp => {
                    commit(ARTICLE_SUCCESS);
                    resolve(resp);
                })
                .catch(err => {
                    commit(ARTICLE_ERROR);
                    reject(err);
                });
        });
    }
};

const mutations = {
    [ARTICLE_REQUEST]: state => {
        state.status = 'loading';
    },
    [ARTICLE_SUCCESS]: state => {
        state.status = 'success';
    },
    [ARTICLE_ERROR]: state => {
        state.status = 'error';
    },
    [ARTICLE_LIST]: (state, resp) => {
        state.status = 'success';
        Vue.set(state, 'articles', resp.articles);
        Vue.set(state, 'total', resp.total);
        Vue.set(state, 'pages', resp.pages);
    },
    [ARTICLE_IMG_LIST]: (state, resp) => {
        Vue.set(state, 'imageList', resp.files);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
