import Vue from 'vue'
import apiCall from '@/utils/apiCall'
import _ from 'lodash'
import {
	DOCTOR_ADD,
	DOCTOR_DELETE,
	DOCTOR_LIST,
	DOCTOR_ERROR,
	DOCTOR_REQUEST,
	DOCTOR_SUCCESS,
	DOCTOR_UPDATE,
	DOCTOR_UPLOAD,
	DOCTOR_PICTUREUPLOAD,
    DOCTOR_PICTUREDELETE
} from './define'

const state = {
	status: '',
	doctors: [],
	total: 0,
	pages: 0
}

const getters = {
	getDoctors: state => state.doctors,
	getDoctorTotal: state => state.total,
	getDoctorPages: state => state.pages
}

const actions = {
	[DOCTOR_REQUEST]: ({commit}, data = {}) => {
		return new Promise((resolve, reject) => {
			commit(DOCTOR_REQUEST)
			apiCall({url: 'doctor/list', data, method: 'POST'})
				.then(resp => {
					commit(DOCTOR_LIST, resp)
					resolve(true)
				})
				.catch(err => {
					commit(DOCTOR_ERROR)
					reject(err)
				})
		})
	},
	[DOCTOR_UPDATE]: ({commit}, data) => {
		var dataDoctor = _.cloneDeep(data.doctor)
		dataDoctor.clinic = data.doctor.clinic._id
		dataDoctor.branch = _.map(data.doctor.branch, '_id')
		dataDoctor.diseases = _.map(data.doctor.diseases, '_id')
		dataDoctor.treatments = _.map(data.doctor.treatments, '_id')
		dataDoctor.city = data.doctor.city._id
		dataDoctor.country = data.doctor.country._id
		return new Promise((resolve, reject) => {
			commit(DOCTOR_REQUEST)
			apiCall({
				url: 'doctor/' + data.uuid,
				data: dataDoctor,
				method: 'PUT'
			})
				.then(resp => {
					commit(DOCTOR_SUCCESS)
					resolve(resp)
				})
				.catch(err => {
					commit(DOCTOR_ERROR)
					reject(err)
				})
		})
	},
	[DOCTOR_ADD]: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			commit(DOCTOR_REQUEST)
			apiCall({
				url: 'doctor/add',
				data: data,
				method: 'POST'
			})
				.then(resp => {
					commit(DOCTOR_SUCCESS)
					resolve(resp)
				})
				.catch(err => {
					commit(DOCTOR_ERROR)
					reject(err)
				})
		})
	},
	[DOCTOR_DELETE]: ({commit}, uuid) => {
		return new Promise((resolve, reject) => {
			commit(DOCTOR_REQUEST)
			apiCall({
				url: 'doctor/' + uuid,
				method: 'DELETE'
			})
				.then(resp => {
					commit(DOCTOR_SUCCESS)
					resolve(resp)
				})
				.catch(err => {
					commit(DOCTOR_ERROR)
					reject(err)
				})
		})
	},
	[DOCTOR_UPLOAD]: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			let formData = new FormData()
			formData.append('file', data.file)

			commit(DOCTOR_REQUEST)
			apiCall(
				{
					url: 'file/profile/image',
					data: formData,
					method: 'POST'
				},
				{user: data.user, 'Content-Type': 'multipart/form-data'}
			)
				.then(resp => {
					commit(DOCTOR_SUCCESS)
					resolve(resp)
				})
				.catch(err => {
					commit(DOCTOR_ERROR)
					reject(err)
				})
		})
	},

	[DOCTOR_PICTUREUPLOAD]: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			let formData = new FormData()
			data.files.forEach((file, index) => {
				formData.append(`files`, file)
			})
            formData.append('type', data.type)
			commit(DOCTOR_REQUEST)
			apiCall(
				{
					url: 'file/uploadpictures',
					data: formData,
					method: 'POST'
				},
				{user: data.user, crossdomain: true, 'Content-Type': 'multipart/form-data'}
			)
				.then(resp => {
                    console.log(1);
					commit(DOCTOR_SUCCESS)
					resolve(resp)
				})
				.catch(err => {
					commit(DOCTOR_ERROR)
					reject(err)
				})
		})
	},
	[DOCTOR_PICTUREDELETE]: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			commit(DOCTOR_REQUEST)
			apiCall(
				{
					url: 'file/removepicture',
					data,
					method: 'POST'
				}
			)
				.then(resp => {
					commit(DOCTOR_SUCCESS)
					resolve(resp)
				})
				.catch(err => {
					commit(DOCTOR_ERROR)
					reject(err)
				})
		})
	},

    
}

const mutations = {
	[DOCTOR_REQUEST]: state => {
		state.status = 'loading'
	},
	[DOCTOR_SUCCESS]: state => {
		state.status = 'success'
	},
	[DOCTOR_ERROR]: state => {
		state.status = 'error'
	},
	[DOCTOR_LIST]: (state, resp) => {
		state.status = 'success'
		Vue.set(state, 'doctors', resp.doctors)
		Vue.set(state, 'total', resp.total)
		Vue.set(state, 'pages', resp.pages)
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
