import VueCookies from 'vue-cookies';

export const setCookie = (key, value, expire = '7d') => {
    return VueCookies.set(key, value, expire);
};

export const getCookie = key => {
    return VueCookies.get(key);
};

export const removeCookie = key => {
    return VueCookies.remove(key);
};
