import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Plugins from './plugins'
import filters from './utils/filters'

import {i18n} from './plugins/i18n'
import config from './config'
import LanguageInput from '@/components/LanguageInput.vue'

Vue.use(Plugins)

Vue.mixin({
	components: {
		LanguageInput
	},
	data: function () {
		return {config}
	},
	methods:{
		getLangItem (obj) {
			var item = _.find(obj, {lang: this.$i18n.locale})
			return item ? item.value : (_.find(obj, {lang: 'en'}) ? _.find(obj, {lang: 'en'}).value : '')
		},
	},
	filters
})

Vue.$cookies.config('7d', '/', config.VUE_APP_COOKIE_DOMAIN)

Vue.config.productionTip = false

new Vue({
	data() {
		return {
			defaultMapOptions: {
				gestureHandling: 'greedy',
				clickableIcons: false,
				fullscreenControl: false,
				mapTypeControl: false,
				streetViewControl: false,
				zoom: 18,
				minZoom: 3,
				center: {
					lat: 36.89659,
					lng: 30.71076
				}
			}
		}
	},
	sockets: {
		connect: function () {
			console.log('socket connected')
		},
		customEmit: function (data) {
			console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
		}
	},
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
