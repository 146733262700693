import Notifications from '@/components/Arg/NotificationPlugin'
import SideBar from '@/components/Arg/SidebarPlugin'

import {extend, configure} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import axiosInstance from './axios'
import VueSweetalert2 from 'vue-sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocketIO from 'vue-socket.io'
import VueTimeago from 'vue-timeago'
import VCalendar from 'v-calendar'
import VueQuillEditor from 'vue-quill-editor'
import VueLazyload from 'vue-lazyload'
import VueMask from 'v-mask'
import {getCookie} from '@/utils/cookie'
import _ from 'lodash'

import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'

import store from '@/store'
import config from '@/config'

// vue-bootstrap
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// asset imports
import '@/assets/scss/style.scss'
import '@/assets/scss/custom.scss'
import '@/assets/css/flags.css'
import '@/assets/vendor/fontawesome/css/fontawesome-all.min.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const loadimage = require('../assets/img/loading.gif')
const errorimage = require('../assets/img/error.png')

const lang = getCookie('user-lang') || 'tr'

import trLocale from 'vee-validate/dist/locale/tr.json'
import enLocale from 'vee-validate/dist/locale/en.json'
import deLocale from 'vee-validate/dist/locale/de.json'

const messageFile = {
	tr: trLocale,
	en: enLocale,
	de: deLocale
}

Object.keys(rules).forEach(rule => {
	extend(rule, {
		...rules[rule],
		message: messageFile[lang]['messages'][rule]
	})
})

export default {
	install(Vue) {
		Object.defineProperty(Vue.prototype, '$_', {value: _})
		Vue.use(GlobalComponents)
		Vue.use(GlobalDirectives)
		Vue.use(SideBar)
		Vue.use(VCalendar)
		Vue.use(VueMask)
		Vue.use(VueSweetalert2)
		Vue.use(VueLazyload, {
			preLoad: 1.3,
			error: errorimage,
			loading: loadimage,
			attempt: 1
		})
		Vue.use(VueQuillEditor)
		Vue.use(VueTimeago, {
			name: 'Timeago',
			locale: lang,
			locales: {
				en: require('date-fns/locale/en-US'),
				tr: require('date-fns/locale/tr')
			}
		})
		Vue.use(VueGoogleMaps, {
			installComponents: true,
			load: {
				key: 'AIzaSyB68p7WrerbVQlepMzUs6vxFMPkFqWWX7I',
				libraries: 'places'
			}
		})
		Vue.use(
			new VueSocketIO({
				transports: ['websocket'],
				debug: false,
				connection: config.VUE_APP_SOCKETURI,
				vuex: {
					store,
					actionPrefix: 'SOCKET_',
					mutationPrefix: 'SOCKET_'
				},
				options: {
					autoConnect: false
				}
			})
		)
		Vue.use(Notifications)
		Vue.use(BootstrapVue)
		Vue.use(IconsPlugin)
		Vue.use(VueCookies)
		Vue.use(VueAxios, axiosInstance)
		configure({
			classes: {
				valid: 'is-valid',
				invalid: 'is-invalid',
				dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
			}
		})
	}
}
