import axios from '../plugins/axios';
import _ from 'lodash';

const apiCall = (data, extraHeader = false) =>
    new Promise((resolve, reject) => {
        try {
            if (extraHeader) {
                _.forEach(extraHeader, (value, key) => {
                    axios.defaults.headers.common[key] = value;
                });
            }
            axios(data)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        } catch (err) {
            reject(err);
        }
    });

export default apiCall;
